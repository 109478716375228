.Home{
    display: flex;
    flex-direction: column;
    padding: auto;
    margin: auto;
    background-color: var(--color-background);
    width: 100%;
    height: 100vh;
}



.ProfilePicture{
    width: 200px;
    height: 200px;
    margin-left: 1.0rem;
    margin-top: 1.0em;
    border-radius: 50%;
    border: 0.5rem solid var(--color-border);
}

@media (max-width: 767px) {
    .Home {
        display: block;
    }
    .ProfilePicture{
        margin-left: 0.0rem;
    }
}

.HomeText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
}

.HomeText h1{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--color-text);
}

.HomeText h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--color-text);
}
/* color palette from <https://github.com/vuejs/theme> */
:root {
    --vt-c-white: #ffffff;
    --vt-c-white-soft: #f8f8f8;
    --vt-c-white-mute: #f2f2f2;
  
    --vt-c-black: rgb(30, 30, 30);
    --vt-c-black-soft: rgb(37, 37, 38);
    --vt-c-black-mute: rgb(50, 50, 51);
  
    --vt-c-indigo: #2c3e50;
  
    --vt-c-divider-light-1: rgba(60, 60, 60, 0.29);
    --vt-c-divider-light-2: rgba(60, 60, 60, 0.12);
    --vt-c-divider-dark-1: rgba(84, 84, 84, 0.65);
    --vt-c-divider-dark-2: rgba(84, 84, 84, 0.48);
  
    --vt-c-text-light-1: var(--vt-c-indigo);
    --vt-c-text-light-2: rgba(60, 60, 60, 0.66);
    --vt-c-text-dark-1: var(--vt-c-white);
    --vt-c-text-dark-2: rgba(235, 235, 235, 0.64);
  }
  
  /* semantic color variables for this project */
  :root {
    --color-background: var(--vt-c-white);
    --color-background-soft: var(--vt-c-white-soft);
    --color-background-mute: var(--vt-c-white-mute);
  
    --color-border: var(--vt-c-divider-light-2);
    --color-border-hover: var(--vt-c-divider-light-1);
  
    --color-heading: var(--vt-c-text-light-1);
    --color-text: var(--vt-c-text-light-1);
  
    --section-gap: 160px;
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --color-background: var(--vt-c-black);
      --color-background-soft: var(--vt-c-black-soft);
      --color-background-mute: var(--vt-c-black-mute);
  
      --color-border: var(--vt-c-divider-dark-2);
      --color-border-hover: var(--vt-c-divider-dark-1);
  
      --color-heading: var(--vt-c-text-dark-1);
      --color-text: var(--vt-c-text-dark-2);
    }
  }
  
  body {
    min-height: 100vh;
    color: var(--color-text);
    background: var(--color-background);
    transition: color 0.5s, background-color 0.5s;
    line-height: 1.6;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
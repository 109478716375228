.Project{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 5vh;

    border: 1px solid var(--color-border);
    border-radius: 5px;

    background-color: var(--color-background-soft);

    width: 20vw; 
}


.ProjectImage {
    height: 14vw;
    padding: 10px;
}

.ProjectTitle{
    font-size: 1rem;
    font-weight: bold;
    color: var(--color-text);
    margin: 0;
    padding: 0;
}

.ProjectDate{
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    color: var(--color-text);
}
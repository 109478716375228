@import '../assets/base.css';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  user-select: none;
  min-height: 100%;
}

::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0);
}
::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(0,0,0,0);
}
::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 10px;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

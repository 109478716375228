.Projects{
    padding: auto;
    margin: auto;
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ProjectList{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.break{
    flex-basis: 100%;
    height: 0;
}

.ProjectLink{
    width: auto;
    height: 100%;
}
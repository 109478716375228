nav{
    background-color: var(--color-background);
    color: var(--color-text);
    height: 50px;
    border: 1px, solid, var(--color-border);
}

.Header{
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 767px) {
    .Header{
        display: block;
    }
}

.header-item{
    padding: 1.0rem;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 1.0rem;
    line-height: 1.5rem;
}

a:link {
    text-decoration: none;
    color: var(--color-text);
}
  
a:visited {
    text-decoration: none;
    color: var(--color-text);
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}